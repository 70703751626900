import { createStore } from 'vuex'
import { dbService } from '@root/firebaseDatabase'

// Create a new store instance.
const store = createStore({
	state() {
		return {
			assessmentData: undefined,
			courseData: undefined,
			webinarData: undefined,
			companyData: undefined,
			formData: undefined,
			eventData: undefined,
			meetingData: undefined,
			accessData: undefined,
			currentUser: undefined,
			alert: undefined,
			courses: undefined,
			webinars: undefined,
			sideMenuView: undefined,
			sideMenuOpen: false,
			viewAs: undefined,
			modal: { show: false },
			overlay: { show: false },
			action: undefined,
			userData: undefined,
			tenant: undefined,
			meetings: [],
			unlockedCourses: [],
			allUsers: undefined,
			lessonStatus: false,
			hideNav: true,
			activeTab: '',
			task: undefined,
			recentUsers: [],
			attendanceData: undefined,
			country: undefined,
			resource: undefined,
			partner: undefined,
			meta: undefined,
			streamLive: false,
			eventLive: undefined,
			loadTime: undefined,
			loginRedirect: undefined,
			redirect: false,
			recording: false,
			replay: false,
			unsubscribe: [],
			disableButton: false,
			fileProgress: undefined,
			pageActiveDrawer: null
		}
	},
	mutations: {
		setPageActiveDrawer(state, element) {
			state.pageActiveDrawer = element
		},
		addUnlockedCourse(state, course) {
			state.unlockedCourses.push(course)
		},
		setDisableButton(state, boolean) {
			state.disableButton = boolean
		},
		setAssessmentData(state, data) {
			state.assessmentData = data
		},
		setReplay(state, boolean) {
			state.replay = boolean
		},
		setRecentUsers(state, users) {
			state.recentUsers = users
		},
		setStreamLive(state, boolean) {
			state.streamLive = boolean
		},
		setRecordingData(state, boolean) {
			state.recording = boolean
		},
		setLoadTime(state, string) {
			state.loadTime = string
		},
		setCourseData(state, object) {
			state.courseData = object
		},
		setWebinarData(state, object) {
			state.webinarData = object
		},
		setFormData(state, object) {
			state.formData = object
		},
		setEventData(state, object) {
			state.eventData = object
		},
		setCompanyData(state, object) {
			state.companyData = object
		},
		setTask(state, object) {
			state.task = object
		},
		setMeetingData(state, object) {
			state.meetingData = object
		},
		setAccessData(state, object) {
			state.accessData = object
		},
		setCountry(state, string) {
			state.country = string
		},
		setMeta(state, object) {
			state.meta = object
		},
		setCourses(state, payload) {
			state.courses = payload
		},
		setWebinars(state, payload) {
			state.webinars = payload
		},
		setMeetings(state, payload) {
			state.meetings = payload
		},
		setResource(state, payload) {
			state.resource = payload
		},
		setForms(state, payload) {
			state.forms = payload
		},
		setCurrentUser(state, payload) {
			state.currentUser = payload
		},
		setAlert(state, payload) {
			state.alert = payload
		},
		setAdminMode(state, payload) {
			state.adminMode = payload
		},
		setModal(state, payload) {
			state.modal = payload
		},
		setOverlay(state, payload) {
			state.overlay = payload
		},
		setAction(state, payload) {
			state.action = payload
		},
		setUserData(state, payload) {
			state.userData = payload
		},
		setLoginRedirect(state, payload) {
			state.loginRedirect = payload
		},
		setTenant(state, payload) {
			state.tenant = payload
			if (payload?.defaultDB) {
				dbService.setTenant(payload.id, true)
			} else {
				dbService.setTenant(payload.id, false)
			}
		},
		setAllUsers(state, payload) {
			state.allUsers = payload
		},
		setLessonStatus(state, boolean) {
			state.lessonStatus = boolean
		},
		setHideNav(state, boolean) {
			state.hideNav = boolean
		},
		setActiveTab(state, string) {
			state.activeTab = string
		},
		setSideMenuView(state, string) {
			state.sideMenuView = string
		},
		setSideMenuOpen(state, boolean) {
			state.sideMenuOpen = boolean
		},
		setAttendanceData(state, object) {
			state.attendanceData = object
		},
		setPartner(state, string) {
			state.partner = string
		},
		setUnsubscribe(state, func) {
			state.unsubscribe = func
		},
		setRedirect(state, boolean) {
			state.redirect = boolean
		},
		setFileProgress(state, payload) {
			state.fileProgress = payload
		}
	},
	actions: {
		updateCurrentUser({ commit }, progress) {
			commit('setCurrentUser', progress)
		},
		updateFileProgress({ commit }, user) {
			commit('setFileProgress', user)
		},
		updateRecentUsers({ commit }, users) {
			commit('setRecentUsers', users)
		},
		showAlert({ commit, dispatch }, alert) {
			dispatch('hideAlert')

			const timer = setTimeout(() => {
				dispatch('hideAlert')
			}, alert.duration || 2000)
			commit('setAlert', { ...alert, timer })
		},
		hideAlert({ commit, getters }) {
			if (getters.getAlert) {
				clearTimeout(getters.getAlert.timer)
				commit('setAlert', undefined)
			}
		},
		updateAllCourses({ commit }, courses) {
			commit('setCourses', courses)
		},
		updateViewAs({ commit }, mode) {
			commit('setAdminMode', mode)
		},
		hideModal({ commit }) {
			commit('setModal', { show: false })
		},
		updateSideMenuView({ commit }, view) {
			commit('setSideMenuView', view)
		},
		showModal({ commit, dispatch }, modalData) {
			dispatch('hideModal')
			setTimeout(() => {
				commit('setModal', { ...modalData, show: true })
			}, 1)
		},
		updateTask({ commit }, task) {
			commit('setTask', task)
		},
		updateMeta({ commit }, meta) {
			commit('setMeta', meta)
		},
		resetMeta({ commit }) {
			commit('setMeta', store.state?.tenant?.meta || undefined)
		},
		resetTab({ commit }) {
			commit('setActiveTab', undefined)
		},
		hideOverlay({ commit }) {
			commit('setOverlay', { show: false })
		},
		showOverlay({ commit, dispatch }, overlayData) {
			dispatch('hideOverlay')
			setTimeout(() => {
				commit('setOverlay', { ...overlayData, show: true })
			}, 1)
		},
		updateAction({ commit }, action) {
			commit('setAction', action)
		},
		resetAction({ commit }) {
			commit('setAction', undefined)
		},
		updateUser({ commit }, userData) {
			commit('setUserData', userData)
		},
		updateRedirect({ commit }, boolean) {
			commit('setRedirect', boolean)
		},
		updateResource({ commit }, resource) {
			commit('setResource', resource)
		},
		updateTenant({ commit }, tenant) {
			commit('setTenant', tenant)
		},
		updateAllMeetings({ commit }, meetings) {
			commit('setMeetings', meetings)
		},
		updateAllUsers({ commit }, users) {
			commit('setAllUsers', users)
		}
	},
	getters: {
		getPageActiveDrawer: (state) => {
			return state.pageActiveDrawer
		},
		getCurrentUser: (state) => {
			return state.currentUser
		},
		getAlert: (state) => {
			return state.alert
		},
		getAllCourses: (state) => {
			return state.courses
		},
		getRecentUsers: (state) => {
			return state.recentUsers
		},
		getTask: (state) => {
			return state.task
		},
		getViewAs: (state) => {
			return state.adminMode
		},
		getModal: (state) => {
			return state.modal
		},
		getActiveTab: (state) => {
			return state.activeTab
		},
		getShowModal: (state) => {
			return state.modal?.show
		},
		getModalComponent: (state) => {
			return state.modal?.component
		},
		getModalProps: (state) => {
			return state.modal?.props
		},
		getOverlay: (state) => {
			return state.overlay
		},
		getShowOverlay: (state) => {
			return state.overlay?.show
		},
		getOverlayComponent: (state) => {
			return state.overlay?.component
		},
		getOverlayProps: (state) => {
			return state.overlay?.props
		},
		getSideMenuView: (state) => {
			return state.sideMenuView
		},
		getSideMenuOpen: (state) => {
			return state.sideMenuOpen
		},
		getAction: (state) => {
			return state.action
		},
		getUserData: (state) => {
			return state.userData
		},
		getTenant: (state) => {
			return state.tenant
		},
		getAssessmentData: (state) => {
			return state.assessmentData
		},
		getCourseData: (state) => {
			return state.courseData
		},
		getCompanyData: (state) => {
			return state.companyData
		},
		getEventData: (state) => {
			return state.eventData
		},
		getCountry: (state) => {
			return state.country
		},
		getWebinarData: (state) => {
			return state.webinarData
		},
		getFormData: (state) => {
			return state.formData
		},
		getMeetingData: (state) => {
			return state.meetingData
		},
		getAccessData: (state) => {
			return state.accessData
		},
		getTenantId: (state) => {
			return state.tenant?.id
		},
		getTenantDomain: (state) => {
			return state.tenant?.domain
		},
		getResource: (state) => {
			return state.resource
		},
		getMeetings: (state) => {
			return state.meetings
		},
		getHideNav: (state) => {
			return state.hideNav
		},
		getAllUsers: (state) => {
			return state.allUsers
		},
		getRedirect: (state) => {
			return state.redirect
		},
		getUserByAttribute: (state) => (attribute, value) => {
			return state.allUsers?.find((user) => user[attribute] === value)
		}
	}
})

export default store
