<template>
	<n-notification-provider>
		<n-message-provider>
			<n-config-provider preflight-style-disabled :theme-overrides="themeOverrides">
				<div
					v-if="
						loaded &&
						(!maintenance ||
							(maintenanceMode?.tenants.length !== 0 &&
								!maintenanceMode?.tenants?.includes(tenant?.id)))
					">
					<TopNav></TopNav>
					<div class="min-h-screen relative" :style="style">
						<MainSideNav v-if="!store.state.hideNav"></MainSideNav>
						<div
							:class="
								route.name !== 'Edit Page'
									? 'w-full h-full align-top 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg px-8 mx-auto py-8'
									: ''
							"
							id="router-view">
							<Alert v-if="store.getters.getAlert" />
							<Modal v-if="store.getters.getShowModal" />
							<Overlay v-if="store.getters.getShowOverlay" />
							<router-view />
						</div>
					</div>
				</div>
				<div
					v-if="
						maintenance &&
						(maintenanceMode?.tenants.length === 0 || maintenanceMode.tenants.includes(tenant?.id))
					">
					<div class="flex items-center justify-center h-screen">
						<div class="flex flex-col items-center justify-center">
							<img v-if="tenant?.branding?.logo" :src="tenant?.branding?.logo" class="w-32 mb-4" />
							<div class="text-3xl font-bold text-center mb-4">
								{{ tenant?.name }} Maintenance Mode
							</div>
							<div class="text-center mb-4" v-if="maintenanceMode.description">
								{{ maintenanceMode.description }}
							</div>
							<div class="text-center mb-4" v-if="maintenanceMode.status">
								{{ maintenanceMode.status }}
							</div>
						</div>
					</div>
				</div>
			</n-config-provider>
		</n-message-provider>
	</n-notification-provider>
</template>

<script setup>
import { ref, computed, onMounted, provide, watch } from 'vue'
import { readData, writeData } from '@utils/DataService'
import MainSideNav from '@ViewComponents/Shared/MainSideNav.vue'
import TopNav from '@ViewComponents/Shared/TopNav.vue'
import Alert from '@ViewComponents/Shared/Popovers/AlertPop.vue'
import Modal from '@ViewComponents/Shared/Popovers/ModalPop.vue'
import Overlay from '@ViewComponents/Shared/Popovers/OverlayPop.vue'
import { useBrowserLocation } from '@vueuse/core'
import { defaultDb } from '@root/firebaseDatabase'
import { onSnapshot, doc } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { NConfigProvider, NNotificationProvider, NMessageProvider } from 'naive-ui'
import Gleap from 'gleap'
import * as Sentry from '@sentry/vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const loaded = ref(false)
const country = ref(undefined)
const maintenanceMode = ref({
	active: false,
	description: '',
	status: '',
	tenants: []
})
const maintenance = ref(false)
const themeOverrides = ref({
	Carousel: {
		dotColor: '#c0c0c0',
		dotColorActive: '#000000'
	}
})

provide(
	'user',
	computed(() => store.state.userData)
)
provide(
	'tenant',
	computed(() => store.state.tenant)
)

onMounted(() => {
	const xhttp = new XMLHttpRequest()
	xhttp.onreadystatechange = function state() {
		if (this.readyState === 4 && this.status === 200) {
			country.value = JSON.parse(this.responseText).country
			store.commit('setCountry', country.value)
		}
	}
	xhttp.open('GET', 'https://restless-disk-8af0.komunily.workers.dev/', true)
	xhttp.send()
})

const style = computed(
	() => `
  --primary-color: #0075FF;
  --primary-hover: #3994FF;
  --primary-background: #0075FF;
  --secondary-color: #000000;
  --success-color: #166534;
  --danger-color: #b91c1c;
  --warning-color: #ca8a04;
  --info-color: #1d4ed8;
  --light-color: #000000;
  --dark-color: #374151
`
)

const user = computed(() => store.getters.getUserData)
const tenant = computed(() => store.state.tenant)
const meta = computed(() => store.state.meta)
const getSideMenuView = computed(() => store.getters.getSideMenuView)
const getCountry = computed(() => store.getters.getCountry)

watch(route, (val, old) => {
	if (val && old && val.fullPath !== old.fullPath) {
		store.dispatch('resetMeta')
		store.dispatch('resetTab')
		store.dispatch('updateMeta', {
			...meta.value,
			title: `${val.name} - ${tenant.value?.meta?.title || tenant.value?.name || ''}`
		})
		store.dispatch('hideModal')
		store.dispatch('hideOverlay')
	}
})

const init = () => {
	const timezone = DateTime.local().toFormat('z')

	if (!user.value?.data?.timezone) {
		writeData({
			ref: user.value.ref,
			operation: 'update',
			data: { timezone }
		}).catch((error) => {
			console.error('~ file: App.vue ~ line 225 ~ init ~ error', error)
		})
	}

	if (!user.value?.data?.region) {
		writeData({
			ref: user.value.ref,
			operation: 'update',
			data: { region: getCountry.value }
		}).catch((error) => {
			console.error('~ file: App.vue ~ line 234 ~ init ~ error', error)
		})
	}
	if (!getSideMenuView.value) {
		store.dispatch('updateSideMenuView', 'member')
	}
	if (!Gleap.isUserIdentified() && window.location.hostname !== 'localhost') {
		const data = {
			name: user.value.data.firstName + ' ' + user.value.data.lastName,
			email: user.value.data.email,
			companyId: tenant.value.id,
			companyName: tenant.value.name
		}

		Gleap.identify(user.value.id, data)
	}
}

watch(
	user,
	async (val, old) => {
		if (val && val !== old) {
			init()
		}
		if (val) {
			Sentry.setUser({ id: user.value.id })
		} else {
			Sentry.setUser(null)
		}
	},
	{ immediate: true }
)

onMounted(async () => {
	const snapshots = store.state.unsubscribe
	if (window.location.hostname !== 'localhost') {
		Gleap.initialize('cGv5MGAtNNYcOHjCsnKaIekrd8oZME1p')
	}
	// this uses defaultDb and should not be updated to use dataService which uses dynamicDb
	const maintenanceRef = doc(defaultDb, 'komunily', 'maintenance')
	const unsub = onSnapshot(maintenanceRef, (doc) => {
		const data = doc.data()
		if (data?.description && data?.status) {
			const stateValue = {
				active: true,
				description: data.description,
				status: data.status
			}
			if (data.tenants) {
				stateValue.tenants = data.tenants
			} else {
				stateValue.tenants = []
			}
			maintenanceMode.value = stateValue
			maintenance.value = true
		} else {
			maintenance.value = false
			maintenanceMode.value = {
				active: false,
				description: '',
				status: '',
				tenants: []
			}
		}
		snapshots.push(unsub)
	})

	// Then handle tenant initialization
	if (!tenant.value) {
		const location = useBrowserLocation()

		const unsubscribe = readData({
			ref: 'tenant',
			filter: {
				where: {
					domains: {
						operator: 'array-contains',
						value: location.value.host
					}
				},
				limit: 1
			},
			listen: true,
			onUpdate: (docs) => {
				if (docs.length > 0) {
					const doc = docs[0]
					store.dispatch('updateTenant', {
						id: doc.id,
						...doc.data,
						ref: doc.ref
					})
					store.dispatch('updateMeta', doc.data.meta)
					store.commit('setUnsubscribe', snapshots)

					// Set favicon if it exists in tenant branding
					if (doc.data?.branding?.favicon) {
						const link =
							document.querySelector("link[rel~='icon']") || document.createElement('link')
						link.type = 'image/x-icon'
						link.rel = 'icon'
						link.href = doc.data.branding.favicon
						document.head.appendChild(link)
					}
				} else {
					console.error('No tenant found for domain:', location.value.host)
				}
				loaded.value = true
				snapshots.push(unsubscribe)
			}
		})
	} else {
		loaded.value = true
	}
})
</script>
<style>
.container {
	display: table;
}
.content {
	display: table-cell;
	vertical-align: top;
}
.sidebar {
	display: table-cell;
	vertical-align: top;
}
@media (max-width: 767px) {
	th.font-normal.text-left.pr-16.pl-10 {
		padding: 8px 5px;
	}
	td.pr-16.pl-10.cursor-pointer {
		padding: 8px;
	}
}
</style>
