<template>
	<div class="bg-white">
		<div class="border-b border-gray-200 relative">
			<div class="absolute flex items-center px-6 top-10">
				<component
					:is="!getSideMenuOpen && sideNavRoutes.includes(route.matched?.[0]?.name) ? PhList : PhX"
					@click.prevent="store.commit('setSideMenuOpen', !getSideMenuOpen)"
					v-if="sideNavRoutes.includes(route.matched?.[0]?.name)"
					class="h-6 w-6 text-gray-150 cursor-pointer md:hidden lg:flex"
					weight="light" />
			</div>

			<div
				class="h-full align-top 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg px-8 py-2 mx-auto w-full flex items-center justify-between relative">
				<div class="flex gap-6 md:flex">
					<div v-if="tenant" class="cursor-pointer md:block hidden">
						<a href="/">
							<img
								:src="
									route.path.includes('/showcase/stream')
										? 'https://imagedelivery.net/zUHvQVaonKX8fuaOjIvxsg/f95240c8-18aa-4e48-311a-931a19de4900/public'
										: tenant.branding?.logo
								"
								class="max-h-16 cursor-pointer"
								:alt="tenant.branding?.logoName" />
						</a>
					</div>
				</div>
				<div class="flex gap-6 justify-between md:justify-end w-full">
					<div class="flex items-center gap-2 lg:gap-6">
						<a
							v-for="(item, index) of navItems"
							:key="index"
							class="cursor-pointer text-lg flex lg:gap-2 items-center group"
							:href="item.href"
							:target="item.target">
							<component :is="item.icon" v-if="item.icon" />
							<span class="text-gray-400 font-light hidden lg:block group-hover:text-blue-600">
								{{ item.name }}
							</span>
						</a>
						<n-popover
							placement="bottom"
							trigger="hover"
							v-if="tenant?.id === 'purebookkeeping-5vk48'">
							<template #trigger>
								<a href="https://pureworkflow.io/" target="_blank">
									<svg
										class="w-6 h-6 text-gray-400 hover:text-blue-600 cursor-pointer"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.2"
											d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
									</svg>
								</a>
							</template>
							<div class="large-text">Access Pure Workflow</div>
						</n-popover>
					</div>
					<div class="flex items-center gap-2 lg:gap-6">
						<div
							v-if="user?.role?.administrator || user?.role?.collaborator"
							id="adminMenuTrigger"
							class="cursor-pointer flex gap-2 items-center relative"
							@click.stop="handleAdminClick"
							@mouseover="
								openMenu = true;
								profileMenu = false
							">
							<PhList class="h-6 w-6 text-gray-400 hover:text-blue-600" weight="light" />
						</div>
						<div
							id="adminMenu"
							@mouseleave="openMenu = false"
							class="absolute right-24 bg-white flex flex-col top-20 rounded-md shadow-xl z-50 w-52"
							v-if="openMenu">
							<div
								class="text-center border-b border-gray-100 py-2 bg-blue-600 text-white text-base">
								Admin Menu
							</div>
							<n-menu :options="catalogue" accordion />
						</div>
						<a
							@click.stop.prevent="handleProfileClick"
							@mouseover="
								profileMenu = true;
								openMenu = false
							"
							id="profileMenuTrigger"
							class="cursor-pointer"
							v-if="user"
							href="/user/profile">
							<div class="flex items-center gap-4">
								<div
									v-if="(user?.firstName && user?.lastName) || user?.profileImage"
									class="rounded-full bg-yellow-500 text-white w-12 h-12 flex items-center justify-center text-xs">
									<img
										class="rounded-full w-12 h-12"
										v-if="user?.profileImage"
										:src="user?.profileImage" />
									<div v-else>
										{{ user?.firstName?.charAt(0) + user?.lastName?.charAt(0) }}
									</div>
								</div>
								<p v-else>
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
									</svg>
								</p>
							</div>
						</a>
						<div v-else>
							<n-button @click="showModal = !showModal" size="medium" type="primary" class="mt-1">
								Login
							</n-button>
						</div>
						<div
							id="profileMenu"
							@mouseleave="profileMenu = false"
							class="absolute right-10 bg-white flex flex-col top-20 rounded-md shadow-xl z-50"
							v-if="profileMenu">
							<n-menu :options="userNav" accordion />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, h, computed, watch, inject, onBeforeUnmount, nextTick } from 'vue'
import { NMenu, NPopover, NButton } from 'naive-ui'
import { useRoute, RouterLink } from 'vue-router'
import { useStore } from 'vuex'
import { showDanger } from '@utils/AlertService'
import { auth } from '@root/firebaseDatabase'
import { AUTH_ERROR_CODES } from '@root/src/constants/Default'
import {
	PhBook,
	PhCalendarBlank,
	PhDesktop,
	PhUsers,
	PhToolbox,
	PhFileText,
	PhImage,
	PhFiles,
	PhCheckCircle,
	PhFolder,
	PhQuestion,
	PhChartBar,
	PhHouseLine,
	PhAppWindow,
	PhUser,
	PhHeart,
	PhFilmStrip,
	PhList,
	PhX,
	PhLock,
	PhBuildings
} from '@phosphor-icons/vue'

const store = useStore()
const route = useRoute()
const profileMenu = ref(false)

const user = computed(() => {
	return store.state?.userData?.data
})

const tenant = inject('tenant')

const getSideMenuOpen = computed(() => {
	return store.getters.getSideMenuOpen
})

const openMenu = ref(false)

const logout = async () => {
	await auth
		.signOut()
		.then(() => {
			store.dispatch('updateCurrentUser', undefined)
			store.commit('setLoginRedirect', undefined)
			store.dispatch('updateViewAs', undefined)
			store.dispatch('updateUser', undefined)
			store.dispatch('updateSideMenuView', undefined)
			window.open('/login', '_self')
		})
		.catch((error) => {
			console.error('🚀 ~ file: TopNav.vue ~ line 196 ~ handleLogout ~ error', error)
			showDanger(AUTH_ERROR_CODES[error.code] || error.message, 5000)
		})
}

const selectedUserNav = (e) => {
	switch (e) {
		case 'profile':
			window.open('/user/profile', '_self')
			break
		case 'company':
			window.open('/user/company', '_self')
			break
		case 'favorites':
			window.open('/user/favorites', '_self')
			break
		case 'studio':
			window.open('/user/studio', '_self')
			break
		case 'logout':
			logout()
			break
		default:
			break
	}
}

const sideNavRoutes = ['Settings', 'Edit Event', 'Events', 'Profile']

const routingLink = (label, href) => {
	return () =>
		h(RouterLink, { to: href, class: 'font-light text-base pr-4' }, { default: () => `${label}` })
}

const navItems = [
{
		name: 'Home',
		href: '/',
		icon: h(PhHouseLine, {
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			weight: 'light'
		})
	},
	{
		name: 'Admin Dashboard',
		href: '/admin/dashboard',
		icon: h(PhAppWindow, {
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			weight: 'light'
		})
	},
	{
		name: 'Learning',
		href: '/learning/',
		icon: h(PhBook, {
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			weight: 'light'
		})
	},
	{
		name: 'Events',
		href: '/events/',
		icon: h(PhCalendarBlank, {
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			weight: 'light'
		})
	},
	{
		name: 'Community',
		href: tenant?.community,
		target: '_blank',
		icon: h(PhUsers, {
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			weight: 'light'
		})
	}
]


const catalogue = [
	{
		label: routingLink('Learning', '/admin/learning'),
		key: 'learning',
		icon: () =>
			h(PhBook, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		label: routingLink('Events', '/admin/events'),
		key: 'events',
		icon: () =>
			h(PhCalendarBlank, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			}),
		children: [
			{
				label: routingLink('Webinars', '/admin/webinars'),
				key: 'webinars',
				icon: () =>
					h(PhDesktop, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			}
		]
	},
	{
		label: routingLink('Community', '/admin/community'),
		key: 'community',
		icon: () =>
			h(PhUsers, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Tools' }),
		key: 'tools',
		icon: () =>
			h(PhToolbox, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			}),
		children: [
			{
				label: routingLink('Pages', '/admin/pages'),
				key: 'pages',
				icon: () =>
					h(PhFileText, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Templates', '/admin/images'),
				key: 'images',
				icon: () =>
					h(PhImage, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Forms', '/admin/forms'),
				key: 'forms',
				icon: () =>
					h(PhFiles, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Assessments', '/admin/assessments'),
				key: 'assessments',
				icon: () =>
					h(PhCheckCircle, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Resources', '/admin/resources'),
				key: 'resources',
				icon: () =>
					h(PhFolder, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Notifications', '/admin/notifications'),
				key: 'notifications',
				icon: () =>
					h(PhQuestion, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('FAQs', '/admin/faqs'),
				key: 'faqs',
				icon: () =>
					h(PhChartBar, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			}
		]
	},
	{
		label: routingLink('Users', '/admin/users'),
		key: 'accounts',
		icon: () =>
			h(PhUsers, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			}),
		children: [
			{
				label: routingLink('Access', '/admin/access'),
				key: 'access',
				icon: () =>
					h(PhLock, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			},
			{
				label: routingLink('Companies', '/admin/companies'),
				key: 'companies',
				icon: () =>
					h(PhBuildings, {
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						weight: 'light'
					})
			}
		]
	},
	{
		label: routingLink('Settings', '/admin/settings'),
		key: 'settings',
		href: '/admin/settings/',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />`
			})
	}
]

const userNav = [
	{
		onClick: () => selectedUserNav('profile'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Profile' }),
		key: 'profile',
		icon: () =>
			h(PhUser, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		onClick: () => selectedUserNav('studio'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Studio' }),
		key: 'studio',
		icon: () =>
			h(PhFilmStrip, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		onClick: () => selectedUserNav('favorites'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Favorites' }),
		key: 'favorites',
		icon: () =>
			h(PhHeart, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		onClick: () => selectedUserNav('company'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Company' }),
		key: 'company',
		icon: () =>
			h(PhBuildings, {
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				weight: 'light'
			})
	},
	{
		onClick: () => selectedUserNav('logout'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Logout' }),
		key: 'logout',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"></path>`
			})
	}
]

const isFirstAdminInteraction = ref(true)
const isFirstProfileInteraction = ref(true)
const isHandlingMenuClick = ref(false)

watch(openMenu, (newValue) => {
	if (!newValue) {
		isFirstAdminInteraction.value = true
	}
})

watch(profileMenu, (newValue) => {
	if (!newValue) {
		isFirstProfileInteraction.value = true
	}
})

const handleAdminClick = (event) => {
	event.stopPropagation()
	isHandlingMenuClick.value = true

	if (profileMenu.value) {
		profileMenu.value = false
		isFirstProfileInteraction.value = true
	}

	if (isFirstAdminInteraction.value) {
		openMenu.value = true
		isFirstAdminInteraction.value = false
	} else {
		openMenu.value = !openMenu.value
	}

	nextTick(() => {
		isHandlingMenuClick.value = false
	})
}

const handleProfileClick = (event) => {
	event.stopPropagation()
	isHandlingMenuClick.value = true

	if (openMenu.value) {
		openMenu.value = false
		isFirstAdminInteraction.value = true
	}

	if (isFirstProfileInteraction.value) {
		profileMenu.value = true
		isFirstProfileInteraction.value = false
	} else {
		profileMenu.value = !profileMenu.value
	}

	nextTick(() => {
		isHandlingMenuClick.value = false
	})
}

const closeMenus = (event) => {
	if (isHandlingMenuClick.value) {
		return
	}

	const adminMenuElement = document.getElementById('adminMenu')
	const profileMenuElement = document.getElementById('profileMenu')
	const adminMenuTrigger = document.getElementById('adminMenuTrigger')
	const profileMenuTrigger = document.getElementById('profileMenuTrigger')

	if (
		(adminMenuTrigger && adminMenuTrigger.contains(event.target)) ||
		(profileMenuTrigger && profileMenuTrigger.contains(event.target)) ||
		(adminMenuElement && adminMenuElement.contains(event.target)) ||
		(profileMenuElement && profileMenuElement.contains(event.target))
	) {
		return
	}

	openMenu.value = false
	profileMenu.value = false
}

const checkSideNav = () => {
	if (sideNavRoutes.includes(route.matched?.[0]?.name)) {
		store.commit('setHideNav', false)
	} else {
		store.commit('setHideNav', true)
	}
}

onMounted(() => {
	checkSideNav()
	document.addEventListener('click', closeMenus)
	isFirstAdminInteraction.value = true
	isFirstProfileInteraction.value = true
})

onBeforeUnmount(() => {
	document.removeEventListener('click', closeMenus)
})

watch(
	() => route.name,
	(value) => {
		checkSideNav(value)
	}
)
</script>
<style scoped>
:deep(i.n-base-icon.n-menu-item-content__arrow) {
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
