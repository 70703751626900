import { AUTH_ERROR_CODES } from '@root/src/constants/Default'
import { log } from '@reusables/logger'
import { auth, functions } from '@root/firebaseDatabase'
import store from '../store/index'
import { showDanger, showSuccess } from './AlertService'
import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	verifyPasswordResetCode,
	confirmPasswordReset
} from 'firebase/auth'
import { serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { readData, writeData } from '@utils/DataService'

const AUTH_CODES = {
	'auth/wrong-password': 'The password is incorrect!',
	'auth/user-not-found': 'User does not exist!'
}

export const fetchAuthState = () => {
	return new Promise((resolve, reject) => {
		auth.onAuthStateChanged(async (res) => {
			if (res?.uid) {
				await store.dispatch('updateCurrentUser', res)
				const unsubscribe = await readData({
					ref: `tenant/${res.tenantId}/users/${store.state.userOverride || res.uid}`,
					listen: true,
					onUpdate: (user) => {
						store.dispatch('updateUser', user)
						const snapshots = store.state.unsubscribe
						snapshots.push(unsubscribe)
						store.commit('setUnsubscribe', snapshots)
						resolve(user.data)
					}
				})
			} else {
				const { unsubscribe } = store.state
				if (typeof unsubscribe !== 'undefined') {
					unsubscribe.forEach((snapshot) => snapshot())
					store.commit('setUnsubscribe', [])
				}
				await store.dispatch('updateViewAs', undefined)
				await store.dispatch('updateCurrentUser', undefined)
				await store.dispatch('updateUser', undefined)
				reject()
			}
		})
	})
}

export const loginWithEmailAndPassword = (email, password, tenantId) => {
	return new Promise((resolve, reject) => {
		auth.tenantId = tenantId
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				store.dispatch('updateCurrentUser', userCredential.user)
				showSuccess('User successfully logged in!', 2000)
				resolve(userCredential.user)
			})
			.catch((error) => {
				showDanger(AUTH_CODES[error.code] || AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error)
			})
	})
}

export const signUpWithEmailAndPassword = ({
	email,
	password,
	tenantId,
	phone,
	firstName,
	lastName,
	company
}) => {
	return new Promise((resolve, reject) => {
		const set = {
			email,
			createdAt: serverTimestamp()
		}
		if (phone) {
			set.phone = phone
		}
		if (firstName) {
			set.firstName = firstName
		}
		if (lastName) {
			set.lastName = lastName
		}
		if (company) {
			set.company = company
		}

		auth.tenantId = tenantId
		createUserWithEmailAndPassword(auth, email, password)
			.then(async (response) => {
				await writeData({
					ref: `tenant/${tenantId}/users/${response.user.uid}`,
					operation: 'set',
					data: set
				}).catch((error) => {
					log('Error creating user document:', error)
				})
				store.dispatch('updateCurrentUser', response.user)
				showSuccess('New user has been successfully signed up!', 5000)
				resolve(true)
			})
			.catch((error) => {
				log('Error during sign up:', error)
				showDanger(AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error.message)
			})
	})
}

export const forgotPassword = (userEmail) => {
	const tenant = store.getters.getTenant
	const forgotPass = httpsCallable(functions, 'userEmailForgotPassword')
	const data = {
		userEmail,
		tenantId: tenant.id,
		tenantURL: tenant.domain
	}
	return forgotPass(data)
}

export const resetPassword = (actionCode, newPassword) => {
	auth.tenantId = store.getters.getTenant.id

	return verifyPasswordResetCode(auth, actionCode)
		.then(() => {
			return confirmPasswordReset(auth, actionCode, newPassword)
				.then(() => {
					return 'Password has been successfully updated, return to login and login with your new password.'
				})
				.catch((error) => {
					log('Error confirming password reset:', error)
					return error.message
				})
		})
		.catch((error) => {
			log('Error verifying password reset code:', error)
			return error.message
		})
}
