import { writeData, serverTimestamp } from '@utils/DataService'
import store from '../store'

export const log = (location, error, data) => {
	const user = store.getters.getCurrentUser
	const timestamp = serverTimestamp()
	const logObject = { location, timestamp }
	if (user) {
		logObject.user = user.uid
	} else {
		logObject.user = 'User was not logged in'
	}
	if (data) {
		logObject.data = data
	}
	if (error && error.message) {
		logObject.error = error.message
		logObject.errorResolved = false
	}
	if (error && !error.message) {
		logObject.error = 'incorrectly formatted error'
		logObject.errorResolved = false
	}
	if (!error) {
		logObject.error = false
	}
	if (logObject.data) {
		Object.keys(logObject.data).forEach((key) => {
			if (logObject.data[key] === undefined) {
				logObject.data[key] = null
			}
			if (
				typeof logObject.data[key] === 'object' &&
				logObject.data[key] instanceof serverTimestamp
			) {
				logObject.data[key] = logObject.data[key].toString()
			}
		})
	}
	return writeData({
		ref: `logger/${store.getters.getTenantId}/log`,
		operation: 'add',
		data: logObject,
	}).catch((err) => new Error(err))
}

export default {
	install: (app) => {
		const appConfig = app.config
		app.provide('logger', log)
		appConfig.globalProperties.$log = log
	}
}
